import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import Snackbar from '@mui/material/Snackbar';
import { Slide } from '@mui/material';
import { amber, green } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';

const PREFIX = 'AppMessageView';

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  icon: `${PREFIX}-icon`,
  iconVariant: `${PREFIX}-iconVariant`,
  message: `${PREFIX}-message`,
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .${classes.success}`]: {
    backgroundColor: green[600],
  },
  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.main,
  },
  [`& .${classes.info}`]: {
    backgroundColor: theme.palette.primary.light,
  },
  [`& .${classes.warning}`]: {
    backgroundColor: amber[700],
  },
  [`& .${classes.icon}`]: {
    fontSize: 20,
    // fontSize: 30,
  },
  [`& .${classes.iconVariant}`]: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  [`& .${classes.message}`]: {
    display: 'flex',
    alignItems: 'center',
    // fontSize: 15,
  },
  [`&.MuiSnackbar-anchorOriginTopCenter`]: {
    top: 75,
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function TransitionLeft(props: any) {
  return <Slide {...props} direction='left' />;
  // return <Slide {...props} direction="top" />;
}

type AppMessageViewProps = {
  className?: string;
  messageId: string;
  message: string | React.ReactElement<any, any>;
  clearInfoView: () => void;
  variant: 'success' | 'error' | 'warning' | 'info';

  [x: string]: any;
};

export interface SnackbarMessage {
  message: string;
  key: string;
}

const AppMessageView: React.FC<AppMessageViewProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const { clearInfoView, className, messageId, message, variant, ...other } = props;
  const Icon = variantIcon[variant];

  const [snackPack, setSnackPack] = React.useState<SnackbarMessage[]>([]);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    // console.log('AppMessageView handleClose');
    // console.log('AppMessageView should clear all messages');
    clearInfoView();

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    clearInfoView();
  };

  const handleExited = () => {
    // console.log('AppMessageView handleExited');
    setMessageInfo(undefined);
    // clearInfoView();
  };

  useEffect(() => {
    // console.log('useEffect messageId', message, messageId, open);
    setSnackPack((prev) => {
      // console.log('setSnackPack prev', prev);

      const x = React.isValidElement(message) ? 'xxx' : message;

      const newMessage: SnackbarMessage = {
        message: x as string,
        key: uuidv4(),
      };

      return [...prev, newMessage];
    });
  }, [messageId]);

  React.useEffect(() => {
    // console.log('React.useEffect', { snackPack, messageInfo, open });

    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
      // clearInfoView();
    }
  }, [snackPack, messageInfo, open]);

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        // vertical: 'top',
        // horizontal: 'center',
      }}
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      onClose={handleClose}
      autoHideDuration={3500}
      TransitionComponent={TransitionLeft}
      TransitionProps={{ onExited: handleExited }}
      message={messageInfo ? messageInfo.message : undefined}
    >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key='close' aria-label='close' color='inherit' onClick={handleClose} size='large'>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </StyledSnackbar>
  );
};

export default AppMessageView;
