import React, { useEffect } from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';
import pluginConfig from './CookieConsentConfig';
import Link from '@mui/material/Link';
import CookieIcon from '@mui/icons-material/Cookie';
import IconButton from '@mui/material/IconButton';
import { appIntl } from '@crema/helpers';

enum PlacementType {
  guest = 'guest',
  user = 'user',
}

const CookieConsentComponent = ({ type }: { type: 'guest' | 'user' }) => {
  useEffect(() => {
    CookieConsent.run(pluginConfig);
  }, []);

  const Intl = appIntl();

  const cookiePreferenceLabel = Intl.formatMessage({ id: 'Cookie preferences' });

  return type === PlacementType.guest ? (
    <a
      href='#'
      onClick={(event) => {
        event.preventDefault();
        CookieConsent.showPreferences();
        return false;
      }}
    >
      {cookiePreferenceLabel}
    </a>
  ) : (
    <>
      <Link
        href='#'
        sx={{ display: { xs: 'none', md: 'block' } }}
        onClick={(event) => {
          event.preventDefault();
          CookieConsent.showPreferences();
          return false;
        }}
      >
        {cookiePreferenceLabel}
      </Link>
      <IconButton
        sx={{ display: { md: 'none' } }}
        title={cookiePreferenceLabel}
        onClick={(event) => {
          event.preventDefault();
          CookieConsent.showPreferences();
          return false;
        }}
        size='small'
      >
        <CookieIcon />
      </IconButton>
    </>
  );
};

export default CookieConsentComponent;
